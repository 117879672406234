<template>
  <div>
    <div class="search">
      <div class="searche_item">
        <el-input v-model="search_val" placeholder="请输入内容"></el-input>
      </div>
      <div class="searche_item">
        <el-button type="primary" @click="search_data">查询</el-button>
      </div>
      <!-- <div class="searche_item">
        <el-button type="text" @click="add_dialog()"> 添加 </el-button>
      </div> -->
    </div>
    <el-table border :data="tableList" style="width: 100%" :height="cheight">
      <el-table-column type="selection" width="55"> </el-table-column>

      <el-table-column label="所属分类" width="100px">
        <template slot-scope="{ row }">
          <span>{{ row.tName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="违法行为" width="100px">
        <template slot-scope="{ row }">
          <span>{{ row.teamName }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="姓名" width="100px">
        <template slot-scope="{ row }">
          <span>{{ row.nName }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="号码种类" width="100px">
        <template slot-scope="{ row }">
          <span>{{ row.nCX }}</span>
        </template>
      </el-table-column>
      <el-table-column label="号牌号码" width="100px">
        <template slot-scope="{ row }">
          <span>{{ row.nHPHM }}</span>
        </template>
      </el-table-column>
      <el-table-column label="车主手机" width="100px">
        <template slot-scope="{ row }">
          <span>{{ row.nTel }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属区域" width="100px">
        <template slot-scope="{ row }">
          <span>{{ row.nCityID_name }}</span>
        </template>
      </el-table-column>

      <!-- <el-table-column label="内容">
        <template slot-scope="{ row }">
          <span>{{ row.nContent }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="短信状态" width="80px">
        <template slot-scope="{ row }">
          <el-tag v-if="row.nIsSms == 1" effect="dark" type="success"
            >已发</el-tag
          >
          <el-tag v-if="row.nIsSms == 0" effect="dark" type="danger"
            >未发</el-tag
          >
          <el-tag v-if="row.nIsSms == 2" effect="dark" type="warning"
            >取消</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column label="时间">
        <template slot-scope="{ row }">
          <span>{{ row.nAddTime }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="{ row, $index }">
          <el-button type="text" size="small" @click="detail_click(row)">
            查看
          </el-button>
          <!-- <el-button type="text" size="small" @click="edit_click(row)">
            修改
          </el-button> -->
          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="deleteRow(row, $index)"
          >
            <el-button type="text" size="small" slot="reference">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="m10">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="recordCount"
        :page-size="query.limit"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <NewsAdd
      :row_type="sel_row"
      :show="show_type"
      @close="close_type"
      @update="getList"
    />
    <el-dialog
      title="信息详情"
      :visible.sync="dialogVisible_detail"
      width="600"
    >
      <div class="detal">
        <div class="item">
          <div class="left">所属分类:</div>
          <div class="right">{{ erow.tName }}</div>
        </div>
        <div class="item">
          <div class="left">违法行为:</div>
          <div class="right">{{ erow.teamName }}</div>
        </div>
        <div class="item">
          <div class="left">号牌种类:</div>
          <div class="right">{{ erow.nCX }}</div>
        </div>
        <div class="item">
          <div class="left">号牌号码:</div>
          <div class="right">{{ erow.nHPHM }}</div>
        </div>

        <div class="item">
          <div class="left">车主手机:</div>
          <div class="right">{{ erow.nTel }}</div>
        </div>
        <div class="item">
          <div class="left">所属区域:</div>
          <div class="right">{{ erow.nCityID_name }}</div>
        </div>
        <div class="item">
          <div class="left">停车场:</div>
          <div class="right">{{ erow.nAddr }}</div>
        </div>
        <div class="item">
          <div class="left">短信状态:</div>
          <div class="right">{{ erow.nIsSms == 1 ? "已发" : "未发" }}</div>
        </div>
        <div class="item">
          <div class="left">内容:</div>
          <div class="right">{{ erow.nContent }}</div>
        </div>

        <div class="item" v-for="it in files" :key="it.id">
          <div class="left">{{ it.fType == 2 ? "视频" : "图片" }}</div>
          <div class="right" v-if="it.fType == 2">
            <video
              :src="it.fUrl"
              style="width: 200px"
              controls
              :autoplay="false"
            ></video>
          </div>
          <div class="right" v-else>
            <a :href="it.fUrl" target="_blank"></a>
            <img
              :src="it.fUrl"
              alt=""
              style="width: 200px"
              :preview="it.id"
              preview-text="描述"
              class="ss"
            />
          </div>
        </div>
        <!-- <div>
          <el-button type="danger" @click="click_fn(2)">取消</el-button
          ><el-button type="success" @click="click_fn(1)">通过</el-button>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import NewsAdd from "./NewsAdd.vue";
export default {
  components: { NewsAdd },
  data() {
    return {
      sel_row: {},
      show_type: false,
      search_val: "",
      recordCount: 0,
      query: {
        page: 1,
        limit: 25,
      },
      tableList: [],
      cheight: window.innerHeight - 280,
      row: {},
      dialogVisible_detail: false,
      files: [],
      erow: {},
      companyPreview: {
        imgList: [], // 所有图片数组
        index: 0, // 当前点击的图片的索引
        infinite: true, // 是否可以循环切换
        popup: false, // 弹窗的显示隐藏
      },
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    click_fn(val) {
      this.$api.news_update({ id: this.erow.id, st: val }).then((res) => {
        this.getList();
        this.$notify({
          title: "温馨提示",
          message: res.data.msg,
          type: "success",
          duration: 2000,
        });
        this.dialogVisible_detail = false;
      });
    },
    detail_click(row) {
      this.$previewRefresh();
      this.$api.news_detail({ id: row.id }).then((res) => {
        this.erow = res.data.row;
        this.files = res.data.files;
        this.dialogVisible_detail = true;
      });
    },
    search_data() {
      this.query.keyName = this.search_val;
      this.getList();
    },
    close_type() {
      this.show_type = false;
    },
    getList() {
      this.$api.news_list(this.query).then((res) => {
        this.tableList = res.data.rows;
        this.recordCount = res.data.total;
        // console.log(res.data);
      });
    },
    pageChange(currPage) {
      this.query.page = currPage;
      this.getList();
    },
    add_dialog() {
      this.show_type = true;
    },
    deleteRow(row) {
      console.log("deleteRow");
      this.$api.news_delete({ id: row.id }).then((res) => {
        this.getList();
        this.$notify({
          title: "温馨提示",
          message: res.data.msg,
          type: "success",
          duration: 2000,
        });
      });
    },
    edit_click(row) {
      row.nChecked_name = row.nChecked == 1 ? "已审" : "未审";
      this.sel_row = row;
      this.show_type = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.ss {
  cursor: pointer;
}
::v-deep .pswp {
  z-index: 150000000;
}
.search {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .searche_item {
    padding-right: 10px;
  }
}

.detal {
  .item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 30px;
    line-height: 25px;
    .left {
      width: 80px;
      text-align: justify;
      text-align-last: justify;
    }
    .right {
      flex: 1;
      padding-left: 10px;
    }
  }
}
</style>
